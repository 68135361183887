import React, { useContext, useEffect, useRef, useState } from "react";
import "./SearchMobile.scss";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { searchData } from "../../../../services/publicService";
import { useDispatch, useSelector } from "react-redux";
import { setFiltersAction } from "../../../../redux/features/filters";
import { FiltersStore } from "../../../../models/PropertyModel";
import {
  cleanFormParams,
  replaceFormParams,
} from "../../helpers/paramsHelpers";
import { AppStore } from "../../../../redux/store";
import { MainModalContext } from "../../../../context/MainModalContext";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  IconBuildingSkyscraper,
  IconMapPin,
  IconMapPins,
  IconBuildingCommunity,
  IconBuilding,
} from "@tabler/icons-react";

interface Props {
  placeholder: string;
  suggestions: string[];
}

const SearchMobile = ({ placeholder = "", suggestions }: Props) => {
  const filters = useSelector((store: AppStore) => store.filters);
  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [dataSearchHits, setDataSearchHits] = useState<any>([]);
  const navigate = useNavigate();

  let { handleMainModal } = useContext(MainModalContext);

  const inputRef = useRef<HTMLInputElement>(null);

  //LOGICA ELASTIC
  const { callEndpoint } = useCallApiAndLoad();

  const seachDataElastic = async (query: string) => {
    setLoadingData(true);
    // const queryData = {
    //   query: {
    //     match: {
    //       value: {
    //         query: query,
    //       }, // Reemplaza "campo" con el nombre del campo que deseas buscar en ElasticSearch
    //     },
    //   },
    //   sort: [
    //     {
    //       search_rank: {
    //         order: "desc",
    //       },
    //     },
    //   ],
    // };
    const queryData = {
      query: {
        bool: {
          must: [
            {
              match: {
                value: {
                  query,
                  analyzer: "custom_edge_ngram_analyzer", // Ensure partial matching
                },
              },
            },
          ],
          should: [
            {
              match_phrase: {
                value: {
                  query, // Exact phrase matching
                  // "boost": 2.0 // Optional: Increase the score for exact matches
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
    };
    const { status, data } = await callEndpoint(searchData(queryData));
    if (status === 200) {
      const filteredSuggestions = data?.hits?.hits?.map(
        (hit: any) => hit?._source
      );
      setDataSearchHits(filteredSuggestions);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    setSelectedOption(null);
    setShowSuggestions(value.trim().length > 0);
    // searchElasticsearch(event.target.value)
    if (value.length >= 3) {
      seachDataElastic(event.target.value);
    } else {
      setDataSearchHits([]);
    }
  };

  const submitFilter = (
    idCity: string,
    typeCity: string,
    lng: number,
    lat: number,
    zoom: number
  ) => {
    const data = {
      location_id: idCity,
      location_type: typeCity,
      lat: lat || -89.18718,
      lng: lng || 13.68935,
      zoom_search: zoom || 10,
    };
    const replaceParamsObject: any = {
      ...filters,
      ...data,
    };

    if (JSON.stringify(data) !== "{}") {
      const filterDataMapped: FiltersStore =
        replaceFormParams(replaceParamsObject);
      dispatch(setFiltersAction(filterDataMapped));
      console.log(filterDataMapped);
      const cleanedFilters = cleanFormParams(filterDataMapped);
      if (Object.keys(cleanedFilters).length > 0) {
        navigate(`?${createSearchParams(cleanedFilters)}`);
      }

      // window.location.reload();
    }
  };

  const handleSuggestionClick = (
    event: React.MouseEvent<HTMLLIElement>,
    id: string,
    type: string,
    long: number,
    lati: number,
    zoomMap: number
  ) => {
    const suggestion = event.currentTarget.dataset.value;
    setSearchText(suggestion || "");
    setSelectedOption(suggestion as string);
    submitFilter(id, type, long, lati, zoomMap);

    if (typeof window !== "undefined") {
      window.localStorage.setItem("city", suggestion as string);
    }

    handleMainModal();
    document.body.style.overflowY = "scroll";
  };

  const handleInputBlur = () => {
    setShowSuggestions(false);
  };

  const dispatch = useDispatch();

  const suggestionList = dataSearchHits?.length > 0 && (
    <ul className="options-list-mobile">
      {dataSearchHits?.map((item: any) => (
        <li
          key={item.id}
          className={`suggestion ${
            selectedOption === item.value ? "selected" : ""
          }`}
          onMouseDown={(e) => e.preventDefault()}
          onClick={(event) =>
            handleSuggestionClick(
              event,
              item.id,
              item.type,
              item?.location?.coordinates[0],
              item?.location?.coordinates[1],
              item?.location?.zoom
            )
          }
          data-value={item.value}
        >
          {item.type === "city" && <IconBuildingSkyscraper className="mr-2" />}
          {item.type === "state" && <IconMapPin className="mr-2" />}
          {item.type === "district" && <IconMapPins className="mr-2" />}
          {item.type === "nb" && <IconBuildingCommunity className="mr-2" />}
          {item.type === "tower" && <IconBuilding className="mr-2" />}

          {item.value}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="search-mobile">
      <div className="w-full">
        <label className="title-label">Busca por zona, ciudad o calle</label>
        <input
          type="text"
          className="search-input"
          placeholder={placeholder}
          value={searchText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          ref={inputRef}
        />
        {loadingData ? (
          <div className="flex justify-center items-center py-5">
            <img
              className="w-12"
              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading_search.gif"
              alt=""
            />
          </div>
        ) : (
          <>{suggestionList}</>
        )}
      </div>
    </div>
  );
};

export default SearchMobile;
